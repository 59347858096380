import _ from 'lodash/fp'
import constants from './common-constants'
import regions from './regions'
// dealer qualified
import dealerIDs from '../../server/config/qualifiedDealers.json'

const localConstants = {
  clientRebate: 7, // percent

  cardTypeMap: {
    V: 'VISA',
    v: 'VISA',
    M: 'MasterCard',
    m: 'MasterCard',
    AX: 'American Express',
  },

  userConfig: {
    checkout: {
      setCreditContrib: false,
    },
  },
  siteConfig: {
    brand: 'HusqvarnaPromoShop',
    siteName: 'HusqvarnaPromoShop',
    selfRegister: true,
    noApproval: true,
    dealerIDs,
    customerTypeFields: [{ name: 'dealerCode', type: 'string', required: true }],
    customizeItemDefaultValue: 3,
    quoteOnHeavyOrders: true,
    deferShippingMessage:
      'Due to the weight of your order we will contact you with final shipping cost later. A flat rate of $200 will be added during checkout.',
  },
  regionConfig: {
    regions,
    userDefined: false,
  },

  programAgreement: {
    en: `<div>
    <p>Buy Together & Save Together!</p>
    <p>Please read before proceeding</p>
    <ul>
        <li>For this Book Now Program, we are proud to bring you the very best in custom retail styled products at competitive prices.</li>
        <li>There are minimums required for each item that must be met in order to have those items fulfilled.</li>
        <li>This works as a group buy, meaning the more you buy together, guarantees the items at the prices offered!</li>
        <li>Each product has a live tracker to show the current buy level.  If you reserve items and see the minimum quantities have been met, you will receive those items.</li>
        <li>Orders will be collected for an 18 day period. Please refer to clock on the next page to see time remaining. Your order must be in before closing time.</li>
    </ul>
    <p>Disclaimer:</p>
    <ul>
        <li>Minimum Book Now Quantity must be reached as a group to proceed with production of that item. If minimum is not met, you will be notified at closing time that the item will not be ordered/fulfilled.</li>
        <li>Products within the Book Now Program are a limited time opportunity and will only be available during the booking period.</li>
        <li>All orders are final at closing.</li>
        <li>You will be billed at the final price break, for all items that met the minimums required at closing of the booking.</li>
        <li>You will receive order notifications at the closing of the booking confirming final quantities and prices to be charged.</li>
        <li>Production and shipping timelines are projected for April of 2024.</li>
    </ul>
</div>`,
    fr: `<div>
    <p>Achetez ensemble et économisez ensemble !</p>
    <p>Veuillez lire avant de continuer.</p>
    <ul>
      <li>Pour ce programme Réserver maintenant, nous sommes fiers de vous offrir le meilleur des produits de vente au détail personnalisés à des prix compétitifs.</li>
      <li>Il existe des minimums requis pour chaque élément qui doivent être respectés afin que ces éléments soient remplis.</li>
      <li>Cela fonctionne comme un achat groupé, c'est-à-dire que plus vous achetez ensemble, plus vous garantissez les articles aux prix proposés !</li>
      <li>Chaque produit dispose d'un tracker en direct pour afficher le niveau d'achat actuel. Si vous réservez des articles et constatez que les quantités minimales ont été atteintes, vous recevrez ces articles.</li>
      <li>Les commandes seront collectées pendant une période de 18 jours. Veuillez vous référer à l'horloge sur la page suivante pour voir le temps restant. Votre commande doit être reçue avant l'heure de fermeture.</li>
    </ul>
    <p>Clause de non-responsabilité</p>
    <ul>
      <li>La quantité minimale de réservation maintenant doit être atteinte en tant que groupe pour procéder à la production de cet article. Si le minimum n'est pas atteint, vous serez informé à l'heure de clôture que l'article ne sera pas commandé/exécuté.</li>
      <li>Les produits du programme Réserver maintenant constituent une opportunité à durée limitée et ne seront disponibles que pendant la période de réservation.</li>
      <li>Toutes les commandes sont définitives à la clôture.</li>
      <li>Vous serez facturé au prix final, pour tous les articles répondant aux minimums requis à la clôture de la réservation.</li>
      <li>Vous recevrez des notifications de commande à la clôture de la réservation confirmant les quantités définitives et les prix à facturer.</li>
      <li>Les délais de production et d'expédition sont prévus pour avril 2024.</li>
    </ul>
  </div>`,
  },
  orderAgreement: {
    en: `<div>
     <p>Product ordered through the Book Now Program are considered final sale, and by proceeding I acknowledge that I am responsible to full payment based on the quantities ordered.</p>
     <p>Billing will be processed after the booking closes, based on the quantities ordered as a group and the items that met the minimums required. I will be charged after the booking closes and will receive an order confirmation as my invoice.</p>
     <p><strong>Click on Continue to proceed with check out.</strong></p>
    </div>`,
    fr: `<div>
     <p>Les produits commandés via le programme Book now sont considérés comme une vente finale et, en poursuivant, je reconnais que je suis responsable du paiement intégral en fonction des quantités commandées.</p>
     <p>La facturation sera traitée après la clôture de la réservation, en fonction des quantités commandées en groupe et des articles répondant aux minimums requis. Je serai facturé après la clôture de la réservation et recevrai une confirmation de commande comme facture.</p>
     <p><strong>Cliquez sur Continuer pour procéder au paiement.</strong></p>
    </div>`,
  },
  customAgreement: {
    en: `<div>
    <p>Buy More, Save More!</p>
    <p>Please read before proceeding:</p>
    <ul>
      <li>This section allows you to customize items by selecting logos and their placement.  </li>
      <li>Add your dealer logo to any item!</li>
      <li>Each item has a minimum order quantity.</li>
    </ul> 
    <p>Disclaimer:</p>
    <ul>
      <li>Customize It orders are made to order and can take up to 2 – 3 weeks for final delivery.</li>
      <li>Rush orders can be accommodated and will be dealt with on a case by case basis. Please specify rush needs in Special Instruction box on product page, or email us directly at <a href="mailto:cepromo@tntworld.com">cepromo@tntworld.com</a></li>
      <li>The online system is used as a guide only. You will be emailed a final proof for approval before production. </li>
      <li>Your CE CSR rep will provide an estimated delivery date upon proof approval.</li>
      <li>Customize It orders are final sale. Credit will only be provided for manufacture defect or incorrect logo placement as per approved proof.</li>
      <li>You will be billed at time of shipping.</li>
      <li>Lower priced items are subject to plus or minus 5% of the quantity ordered. You will be billed for final quantity shipped.</li>
    </ul>
    </div>`,
    fr: `<div>
    <p>Achetez plus, économisez plus!</p>
    <p>Veuillez lire ce qui suit avant de continuer.</p>
    <ul>
      <li>Cette section vous permet de personnaliser des articles en sélectionnant les logos désirés et en choisissant leur emplacement.  </li>
      <li>Ajoutez votre logo de concessionnaire à n'importe quel article!</li>
      <li>Une quantité minimale s'applique à chaque article commandé. Plus la quantité commandée est élevée, plus les prix sont bas.</li>
    </ul>
    <p>Conditions :</p>
    <ul>
      <li>Les articles Customize It sont faits sur commandes et sont livrés en deux ou trois semaines.</li>
      <li>Les commandes urgentes sont possibles et seront jugées au cas par cas. Veuillez spécifier vos besoins urgents dans l'encadré « Instructions spéciales » sur la page du produit, ou écrivez-nous directement par courriel à l'adresse <a href="mailto:cepromo@tntworld.com">cepromo@tntworld.com</a>.</li>
      <li>Le système en ligne ne sert que de repère. Nous vous enverrons une épreuve finale par courriel pour approbation avant d'amorcer la production.</li>
      <li>Après l'approbation des épreuves, votre représentant du service à la clientèle CE vous fournira une date de livraison estimée.</li>
      <li>Les commandes Customize It sont finales. Elles seront remboursées seulement en cas de défaut de fabrication ou d'emplacement inexact du logo par rapport aux épreuves approuvées.</li>
      <li>Vous recevrez votre facture au moment de la livraison.</li>
      <li>Une variation de plus ou moins 5 % s'applique à la quantité commandée d'articles à prix réduit. Votre facture sera établie en fonction de la quantité réelle d'articles livrée.</li>
    </ul>
    </div>`,
  },
  receiptBlurb: {
    en: `<div class="small-12 large-3 columns disclaimer">
      <p>As fulfilled and processed by<br />
      The Next Trend Designs Inc.<br />
      1102 Aerowood Drive<br />
      Mississauga, ON, L4W 1Y5</p>
      </div>
      <div class="small-12 large-9 columns terms">
      <p style="margin-bottom: 0;"><strong>Term & Conditions:</strong></p>
      <ol style="margin-bottom: 0.3rem;">
      <li>PLEASE NOTE THAT ALL FIGURES ARE IN CANADIAN DOLLARS</li>
      <li>Local taxes will be invoiced based on shipping address.</li>
      <li>Duty, taxes and brokerage are the responsibility of the customer/purchaser for any applicable International shipments (FOB Mississauga, ON, Canada).</li>
      <li>This represents both your order confirmation and Invoice. You will not receive any further documentation regarding your order.</li>
      </ol>
      <p>HST Reg # 13961 5389 RT0001</p>
      </div>`,
    fr: `<div class="small-12 large-3 columns disclaimer">
      <p>Telle que remplie et traitée par<br />
      The Next Trend Designs Inc.<br />
      1102 Aerowood Drive<br />
      Mississauga, ON, L4W 1Y5</p>
      </div>
      <div class="small-12 large-9 columns terms">
      <p style="margin-bottom: 0;"><strong>Modalités :</strong></p>
      <ol style="margin-bottom: 0.3rem;">
      <li>VEUILLEZ NOTER QUE TOUS LES MONTANTS SONT INDIQUÉS EN DOLLARS CANADIENS</li>
      <li>Les taxes locales seront facturées en fonction de l'adresse de livraison.</li>
      <li>Les droits, taxes et frais de courtage sont la responsabilité du client ou de l'acheteur pour tout envoi international applicable (FOB Mississauga, Ontario, Canada).</li>
      <li>La présente constitue à la fois votre confirmation de commande et votre facture. Vous ne recevrez aucun autre document relativement  à votre commande.</li>
      </ol>
      <p>HST Reg # 13961 5389 RT0001</p>
      </div>`,
  },
  programReceiptBlurb: {
    en: `<div class="small-12 large-3 columns disclaimer">
      <p>As fulfilled and processed by<br />
      The Next Trend Designs Inc.<br />
      1102 Aerowood Drive<br />
      Mississauga, ON, L4W 1Y5</p>
      </div>
      <div class="small-12 large-9 columns terms">
      <p style="margin-bottom: 0;"><strong>Term & Conditions:</strong></p>
      <ol style="margin-bottom: 0.3rem;">
      <li>PLEASE NOTE THAT ALL FIGURES ARE IN CANADIAN DOLLARS</li>
      <li>Local taxes will be invoiced based on shipping address.</li>
      <li>Duty, taxes and brokerage are the responsibility of the customer/purchaser for any applicable International shipments (FOB Mississauga, ON, Canada).</li>
      </ol>
      <p>HST Reg # 13961 5389 RT0001</p>
      </div>`,
    fr: `<div class="small-12 large-3 columns disclaimer">
      <p>Telle que remplie et traitée par<br />
      The Next Trend Designs Inc.<br />
      1102 Aerowood Drive<br />
      Mississauga, ON, L4W 1Y5</p>
      </div>
      <div class="small-12 large-9 columns terms">
      <p style="margin-bottom: 0;"><strong>Modalités :</strong></p>
      <ol style="margin-bottom: 0.3rem;">
      <li>VEUILLEZ NOTER QUE TOUS LES MONTANTS SONT INDIQUÉS EN DOLLARS CANADIENS</li>
      <li>Les taxes locales seront facturées en fonction de l'adresse de livraison.</li>
      <li>Les droits, taxes et frais de courtage sont la responsabilité du client ou de l'acheteur pour tout envoi international applicable (FOB Mississauga, Ontario, Canada).</li>
      </ol>
      <p>HST Reg # 13961 5389 RT0001</p>
      </div>`,
  },
  customReceiptBlurb: {
    en: `<div class="small-12 large-3 columns disclaimer">
      <p>As fulfilled and processed by<br />
      The Next Trend Designs Inc.<br />
      1102 Aerowood Drive<br />
      Mississauga, ON, L4W 1Y5</p>
      </div>
      <div class="small-12 large-9 columns terms">
      <p style="margin-bottom: 0;"><strong>Term & Conditions:</strong></p>
      <ol style="margin-bottom: 0.3rem;">
      <li>PLEASE NOTE THAT ALL FIGURES ARE IN CANADIAN DOLLARS</li>
      <li>Local taxes will be invoiced based on shipping address.</li>
      <li>Duty, taxes and brokerage are the responsibility of the customer/purchaser for any applicable International shipments (FOB Mississauga, ON, Canada).</li>
      </ol>
      <p>HST Reg # 13961 5389 RT0001</p>
      </div>`,
    fr: `<div class="small-12 large-3 columns disclaimer">
      <p>Telle que remplie et traitée par<br />
      The Next Trend Designs Inc.<br />
      1102 Aerowood Drive<br />
      Mississauga, ON, L4W 1Y5</p>
      </div>
      <div class="small-12 large-9 columns terms">
      <p style="margin-bottom: 0;"><strong>Modalités :</strong></p>
      <ol style="margin-bottom: 0.3rem;">
      <li>VEUILLEZ NOTER QUE TOUS LES MONTANTS SONT INDIQUÉS EN DOLLARS CANADIENS</li>
      <li>Les taxes locales seront facturées en fonction de l'adresse de livraison.</li>
      <li>Les droits, taxes et frais de courtage sont la responsabilité du client ou de l'acheteur pour tout envoi international applicable (FOB Mississauga, Ontario, Canada).</li>
      </ol>
      <p>HST Reg # 13961 5389 RT0001</p>
      </div>`,
  },

  locale: {
    en: {
      currency: {
        symbol: '$',
        decimal: '.',
        thousand: ',',
        precision: 2,
        format: '%s%v',
      },
      upsServiceTypeMap: {
        70: 'UPS Access Point Economy', // Canadian domestic shipments
        12: 'UPS 3 Day Select', // Shipments originating in Canada
        '08': 'UPS Worldwide Expedited International', // Shipments originating in Canada
        '01': 'UPS Express', // Canadian domestic shipments
        '02': 'UPS Expedited', // Canadian domestic shipments
        13: 'UPS Express Saver', // Canadian domestic shipments
        14: 'UPS Express Early', // Canadian domestic shipments
        65: 'UPS Express Saver', // Shipment originating in Canada (Domestic & Int'l)
        11: 'UPS Standard', // Shipments originating in Canada (Domestic and Int'l)
        '07': 'UPS Worldwide Express', // International shipments originating in Canada
        54: 'UPS Worldwide Express Plus', // International shipments originating in Canada
        TBD: 'Order exceeds max weight for single package, get an estimate and update the order.',
      },
    },
    fr: {
      currency: {
        symbol: '$',
        decimal: ',',
        thousand: ' ',
        precision: 2,
        format: '%v %s',
      },
      upsServiceTypeMap: {
        70: 'UPS Access Point Economy', // Canadian domestic shipments
        12: 'UPS 3 Day Select', // Shipments originating in Canada
        '08': 'UPS Worldwide Expedited International', // Shipments originating in Canada
        '01': 'UPS Express', // Canadian domestic shipments
        '02': 'UPS Expedited', // Canadian domestic shipments
        13: 'UPS Express Saver', // Canadian domestic shipments
        14: 'UPS Express Early', // Canadian domestic shipments
        65: 'UPS Express Saver', // Shipment originating in Canada (Domestic & Int'l)
        11: 'UPS Standard', // Shipments originating in Canada (Domestic and Int'l)
        '07': 'UPS Worldwide Express', // International shipments originating in Canada
        54: 'UPS Worldwide Express Plus', // International shipments originating in Canada
        TBD: 'Order exceeds max weight for single package, get an estimate and update the order.',
      },
    },
  },
}

export default _.assign(constants, localConstants)
