import _ from 'lodash/fp'
import * as types from '@/store/mutation-types'
import auth from '@/util/auth'

const actions = {
  completeLogin({ commit }, user) {
    // do stuff
    // console.info(' :: vuex login')
    commit(types.LOGIN, user)
    return Promise.resolve(true)
  },

  logout({ commit }) {
    return auth.logout(() => {
      commit(types.LOGOUT)
    })
  },

  // // change language
  // activate ({ commit }, ln) {
  //   document.documentElement.lang = ln
  //   commit(types.ACTIVE, ln)
  // },

  // // global action to pass error message to view
  // flashErr ({ commit }, msg) commit(types.FLASH_ERROR, msg,

  // getHomeConfig ({ commit }) {
  //   return contentApi.get({ url: contentApi.url + '/home-config' })
  //     .then(res => {
  //       commit(types.HOME_CONFIG, res[0].config)
  //     })
  // },

  //
  // USERS
  //
  checkUser({ commit }) {
    auth
      .checkAuth(this)
      .then(res => {
        commit(types.AUTHENTICATED_USER, res.user)
        commit(types.CART_DATA, res.user.profile)
      })
      .catch(err => {
        commit(types.USER_NOT_LOGGED_IN)
        return err
      })
  },

  getUser({ commit }, id) {
    this.$http
      .get(`/users/${id}`)
      .then(res => commit(types.PROFILE_UPDATE, res.data.profile))
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  },

  // getUsers ({ commit }) {
  //   userApi.get()
  //     .then(data => commit(types.GOT_USERS, data))
  //     .catch(err => {
  //       console.log('Err: ', err)
  //       commit(types.REQ_ERROR, err)
  //     })
  // },

  //   addUser ({ commit }, data) {
  // //   commit(types.ADDED_USER, data)
  // // },

  //   editUser ({ commit }, data) {
  // //   commit(types.UPDATED_USER, data)
  // // },

  emptyCart({ state, dispatch }, type) {
    const _p = _.assign({}, state.user.profile)
    const _c = _p[type] || _p.shoppingcarts[type]
    _c.items = {}
    _c.details = {}
    dispatch('profileUpdate', { user: state.user, profile: _p })
  },

  profileUpdate({ commit }, payload) {
    const _user = _.assign({}, payload.user)
    _user.profile = payload.profile
    const _url = payload.preorder
      ? '/users/profile?preorder=' + payload.preorder
      : '/users/profile'
    return this.$http
      .post(_url, _user)
      .then(data => {
        auth.updateUserProfile(data.data.profile)
        commit(types.PROFILE_UPDATE, data.data.profile)
        return data.data
      })
      .catch(err => {
        console.error(' :: error updating profile', err)
      })
  },

  ensureProfilePreorders({ commit }) {
    commit(types.HAVE_PREORDER)
  },

  // removeUser ({ commit }, data) {
  //   commit(types.DELETE_USER, data)
  // },

  //
  // ROLES
  //
  getRole({ commit }, id) {
    this.$http
      .get(`/roles/${id}?populate=true`)
      .then(data => commit(types.GOT_ROLE, data))
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  },

  getRoles({ commit }) {
    this.$http
      .get('/roles?populate=true')
      .then(data => commit(types.GOT_ROLES, data))
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  },

  //
  // CATEGORIES
  //
  getCategory({ commit }, id) {
    this.$http
      .get(`/categories/${id}?populate=true`)
      .then(data => commit(types.GOT_CATEGORY, data))
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  },

  getCategories({ commit }) {
    this.$http
      .get('/categories?populate=true')
      .then(data => commit(types.GOT_CATEGORIES, data))
      .catch(err => {
        console.log('Err: ', err)
        commit(types.REQ_ERROR, err)
      })
  }
}

export default actions
